import * as React from 'react';
import BaseLayout from '../layouts/BaseLayout';

const NotFoundPage = () => {
  return (
    <BaseLayout title="404">
      <h1>Not found</h1>
    </BaseLayout>
  );
};

export default NotFoundPage;
